import React from 'react';

import Layout, {
    bgImageDefault,
    Col2,
    Col3,
    PageHeaderA,
    PageSection,
    Ul,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { ContactSection } from '../components/contact';
import { Tag, TagList } from '../components/tags';
import { makeSectionId } from '../components/jumpboxlink';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box } from '../components/box';
import { Checklist } from '../components/checklist';
import { ServiceJumpTable } from '../components/servicejumptable';
import Seo from '../components/seo';

function RM({ data, location }) {
    const { t } = useTranslation();
    const overview = [
        {
            title: t(
                'application_risk_title',
                'Risikoidentifikation, Bewertung und Priorisierung'
            ),
            desc: t(
                'application_risk_desc',
                'Das Risikomanagement beinhaltet die systematische Identifikation von potenziellen Risiken, eine detaillierte Analyse ihrer Auswirkungen sowie die Priorisierung der erforderlichen Maßnahmen, um die verfügbaren Ressourcen effektiv zu lenken. Dieser Prozess basiert auf etablierten Normen und Standards wie ISO/IEC 27005 und BSI IT-Grundschutz 200-3 und ermöglicht somit den Aufbau eines robusten Sicherheitsrisikomanagements.'
            ),
            labels: [],
        },
        {
            title: t(
                'safety_metrics_title',
                'Sicherheitsmetriken und Risikokommunikation'
            ),
            desc: t(
                'safety_metrics_desc',
                'Sicherheitsmetriken und Risikokommunikation spielen eine entscheidende Rolle in einem effektiven Sicherheitsmanagement. Durch die Bereitstellung aussagekräftiger Metriken und Berichte erhalten Verantwortliche die Grundlage für fundierte Entscheidungen und einen klaren Überblick über die aktuelle Risikolage. Gleichzeitig wird die transparente Kommunikation von Sicherheitsrisiken im Unternehmen gefördert, indem relevante Stakeholder die notwendigen Informationen erhalten, um proaktiv auf potenzielle Bedrohungen reagieren zu können.'
            ),
            labels: [],
        },
    ];

    return (
        <Layout
            location={location}
            bgImage={bgImageDefault.platform}
            headerContent={
                <PageHeaderA
                    title="Risk Management"
                    text={[
                        t(
                            'subtitle',
                            'Frühzeitige Identifikation, Bewertung und Priotisierung von potenziellen Risiken, um Ressourcen effizient zu nutzen und die Unternehmenssicherheit zu stärken.'
                        ),
                    ]}
                    image={
                        <StaticImage
                            src="../images/headers/srm.jpg"
                            alt="Security-Rating"
                            loading="eager"
                            objectFit="cover"
                            placeholder="none"
                            className="aspect-video"
                            width={500}
                        />
                    }
                />
            }
        >
            <Seo title={t('title', 'Risk Management')} />
            <PageSection size="big">
                <PageSubtitle className="mt-4">
                    <Trans i18nKey="intro_title">
                        Umfassendes Risikomanagement, um digitale Bedrohungen
                        frühzeitig zu erkennen, effektiv zu bewerten und
                        strategisch zu priorisieren.
                    </Trans>
                </PageSubtitle>
                <PageText className="mt-4">
                    <Trans i18nKey="intro_2">
                        Das condignum Risikomanagement-Modul ist die ideale
                        Lösung für Unternehmen, die ihre digitale Sicherheit auf
                        ein neues Niveau heben möchten. Mit umfassenden
                        Funktionen zur Identifizierung, Bewertung und Behandlung
                        von Cybersicherheitsrisiken ermöglicht die condignum
                        Plattform einen proaktiven Ansatz zum Schutz von
                        sensiblen Daten. Durch die Integration von Workflows und
                        Risikokatalogen wird ein effektives Risikomanagement
                        gemäß den Standards ISO/IEC 27005 oder BSI
                        IT-Grundschutz 200-3 ermöglicht und die Einhaltung
                        dieser Sicherheitsstandards sichergestellt.
                    </Trans>
                </PageText>
            </PageSection>

            <ServiceJumpTable items={overview} type="platform" />

            <PageSection size="big">
                <PageTitle className="mt-4">
                    <Trans i18nKey="risk_why">Warum Risk Management?</Trans>
                </PageTitle>
                <PageSubtitle className="mt-2">
                    <Trans i18nKey="risk_why_subtitle_1">
                        Frühzeitige und einfache Erkennung von Risiken
                    </Trans>
                </PageSubtitle>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'risk_why_check_1',
                            'Einfache und schnelle Erfassung potenzieller Risiken bevor sie zu einer ernsthaften Bedrohung werden.'
                        ),
                        t(
                            'risk_why_check_2',
                            'Integrierte Risikokataloge zur schnellen Identifikation neuer Risiken.'
                        ),
                    ]}
                />
                <PageSubtitle className="mt-4">
                    <Trans i18nKey="risk_why_subtitle_2">
                        Effiziente Ressourcennutzung
                    </Trans>
                </PageSubtitle>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'risk_why_check_3',
                            'Ein zentrales Risikoregister hilft den Überblick zu behalten und Ressourcen effizienter einzusetzen, um auf die wichtigsten Sicherheitsrisiken zu reagieren.'
                        ),
                    ]}
                />
                <PageSubtitle className="mt-4">
                    <Trans i18nKey="risk_why_subtitle_3">
                        Dokumentation und Compliance
                    </Trans>
                </PageSubtitle>
                <Checklist
                    className="mt-6 ml-2 max-w-3xl"
                    texts={[
                        t(
                            'risk_why_check_4',
                            'Erleichtere Dokumentation und Nachhaltung von Sicherheitsmaßnahmen ermöglicht die bessere Einhaltung von Compliance-Anforderungen.'
                        ),
                        t(
                            'risk_why_check_5',
                            'Stärkung der Security Posture ihres Unternehmens.'
                        ),
                    ]}
                />
            </PageSection>

            <LabeledSection title={overview[0].title} isDark >
                <PageText>
                    <Trans i18nKey="application_risk_desc">
                        Das Risikomanagement beinhaltet die systematische
                        Identifikation von potenziellen Risiken, eine
                        detaillierte Analyse ihrer Auswirkungen sowie die
                        Priorisierung der erforderlichen Maßnahmen, um die
                        verfügbaren Ressourcen effektiv zu lenken. Dieser
                        Prozess basiert auf etablierten Normen und Standards wie
                        ISO/IEC 27005 und BSI IT-Grundschutz 200-3 und
                        ermöglicht somit den Aufbau eines robusten
                        Sicherheitsrisikomanagements.
                    </Trans>
                </PageText>
            </LabeledSection>
            <LabeledSection title={overview[1].title}>
                <PageText>
                    <Trans i18nKey="safety_metrics_desc">
                        Sicherheitsmetriken und Risikokommunikation spielen eine
                        entscheidende Rolle in einem effektiven
                        Sicherheitsmanagement. Durch die Bereitstellung
                        aussagekräftiger Metriken und Berichte erhalten
                        Verantwortliche die Grundlage für fundierte
                        Entscheidungen und einen klaren Überblick über die
                        aktuelle Risikolage. Gleichzeitig wird die transparente
                        Kommunikation von Sicherheitsrisiken im Unternehmen
                        gefördert, indem relevante Stakeholder die notwendigen
                        Informationen erhalten, um proaktiv auf potenzielle
                        Bedrohungen reagieren zu können.
                    </Trans>
                </PageText>
            </LabeledSection>
            <ContactSection isDark={false} />
        </Layout>
    );
}

function LabeledSection({
    children,
    title,
    subtitle,
    labels = [],
    isDark = false,
}) {
    return (
        <PageSection
            size="big"
            id={makeSectionId(title)}
            isDark={isDark}
            hasPadding={true}
        >
            <PageTitle className="md:flex relative">
                {title}
                <TagList
                    as="small"
                    labels={labels}
                    className="hidden md:flex ml-2"
                >
                    {labels.map(label => (
                        <Tag key={label} as="span">
                            {label}
                        </Tag>
                    ))}
                </TagList>
            </PageTitle>
            <PageSubtitle>{subtitle}</PageSubtitle>
            <TagList labels={labels} className="-mt-5 mb-8 md:hidden">
                {labels.map(label => (
                    <Tag key={label}>{label}</Tag>
                ))}
            </TagList>
            {children}
        </PageSection>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["risk-management"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default RM;
